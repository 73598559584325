import React from "react";
import Loader from '../loader';



const PricingCard = ({ isSubscribed, 
    handleSubscribe,
    handleCancel,
    currentPlan,
    subscriptionDate,
    expirationDate,
    isSubscribing,
    cta,
    isCancelling,
    canTry
}) => {
//   const isSubscribed = false; // Change to true to simulate subscription
  const subscriptionDetails = {
    packageName: currentPlan,
    startDate: subscriptionDate,
    endDate: expirationDate,
  };

  const containerStyle = {
    // minHeight: "100vh",
    backgroundColor: "white",
    color: "black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
  };

  const cardStyle = {
    width: "100%",
    maxWidth: "400px",
    textAlign: "center",
    border: "1px solid #e5e7eb",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "16px",
    overflow: "hidden",
    marginBottom: "16px",
  };

  const headerStyle = {
    padding: "16px",
    fontSize: "24px",
    fontWeight: "bold",
  };

  const contentStyle = {
    padding: "16px",
  };

  const footerStyle = {
    padding: "16px",
  };

  const buttonStyle = {
    backgroundColor: "black",
    color: "white",
    padding: "12px 16px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    width: "100%",
  };
//   isSubscribed = false;

  const cancelButtonStyle = {
    backgroundColor: "red",
    color: "white",
    padding: "12px 16px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      {!isSubscribed ? (
        <div style={cardStyle}>
          <div style={headerStyle}>Primary Plan</div>
          <div style={contentStyle}>
            <p>Access all features of Talent ID</p>
            <ul style={{ textAlign: "left", marginTop: "16px" }}>
              <li>✅ $19.99 one-time fee after trial</li>
              <li>✅ $4.99 annual renewal</li>
              <li>✅ Store up to 2GB of data</li>
            </ul>
          </div>
          <div style={footerStyle}>
            <div style={{display: "flex" , width: "100%", margin: "40px auto", justifyContent: "center"}}>
            <button onClick={handleSubscribe} style={{ backgroundColor: "black", display: "flex" }}>
                <div>{cta}</div>
                <Loader isLoading={isSubscribing} />
            </button>
          </div>

          </div>
          {canTry && <div style={{margin: "0px 15px"}}> You won't be charged until the trial period is over and you can cancel anytime!</div>}
        </div>
      ) : (
        <div style={cardStyle}>
          <div style={headerStyle}>Your Subscription</div>
          <div style={contentStyle}>
            <p><strong>Package:</strong> {subscriptionDetails.packageName}</p>
            <p><strong>Start Date:</strong> {subscriptionDetails.startDate}</p>
            <p><strong>Next Annual Hosting Renewal Date:</strong> {subscriptionDetails.endDate}</p>
          </div>
          <div style={footerStyle}>
            <div style={{display: "flex" , width: "100%", margin: "40px auto", justifyContent: "center"}}>
                <button style={{ backgroundColor: "black", display: "flex" }} onClick={handleCancel} >
                    <div>Cancel</div>
                    <Loader isLoading={isCancelling}/>
                    </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingCard;
