import React, { useState, useRef, useEffect} from 'react';
import VideoRecorder from './video';
import PhotoGallery from './photoGallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShareNodes} from '@fortawesome/free-solid-svg-icons';
import { faShareSquare, faArrowAltCircleDown, faUser as faUserReg} from '@fortawesome/free-regular-svg-icons';
// import {faCloudArrowDown} from '@fortawesome/free-solid-svg-icons';
// import {ReactComponent as indeedLogo} from "./assets/svg/indeed.svg";
import indeedLogo from "./assets/svg/indeed.svg";
import CustomColorPalette from './CustomColorPalette';
import { useLocation, useSearchParams } from 'react-router-dom';
import Loader from "./loader"
import Logo from "./logo";
import { useParams } from 'react-router-dom'
import MorePopMenu from './more_pop_menu';
import DrawerMenu from './drawer';
import ErrorBoundary from './error_boundary';
import DialogBox from './dialog/dialog';
import { useNavigate } from 'react-router-dom';
import Snackbar from './custom_snackbar';


function generateRandomString() {
    return Math.random().toString(36).substr(2, 10);
  }
  

function ProfilePage() {

    const [showVideo1, setShowVideo1] = useState(true);
    const [showVideo2, setShowVideo2] = useState(true);
    const [showVideo3, setShowVideo3] = useState(true);
    const [video1Url, setVideo1Url] = useState(null);
    const [video1File, setVideo1File] = useState(null);
    const [video2Url, setVideo2Url] = useState(null);
    const [video2File, setVideo2File] = useState(null);
    const [video3Url, setVideo3Url] = useState(null);
    const [video3File, setVideo3File] = useState(null);
    const [profilePicUrl, setProfilePictureUrl] = useState(null);
    const [profilePicFile, setProfilePicFile] = useState(null);
    const [video1Transcription, setVideo1Transcription] = useState(null);
    const [video2Transcription, setVideo2Transcription] = useState(null);
    const [video3Transcription, setVideo3Transcription] = useState(null); 
    const [shareableUrl, setShareableUrl] = useState("https://yourtalentid.com/");
    // const [shareableUrl, setShareableUrl] = useState("http://localhost:3000/");

    const [selectedImageId, setSelectedImageId] = useState();
    const [portfolioImageUrls, setPortfolioMediaUrls] = useState([]);
    const [portfolioMediaFiles, setPortfoliioMediaFiles] = useState([]);
    const [portfolioMediaTypes, setPortfolioMediaTypes] = useState([]);
    const [backgroundColor, setBackgroundColor] = useState('white');
    const [foregroundColor, setForegroundColor] = useState('black');
    const [isHovered, setIsHovered] = useState(false);


    // Loaders
    const [isSavingFormData, setIsSavingFormData] = useState(false);
    const [isPublishingVideo1, setIsPublishingVideo1] = useState(false);
    const [isPublishingVideo2, setIsPublishingVideo2] = useState(false);
    const [isPublishingVideo3, setIsPublishingVideo3] = useState(false);
    const [isSavingVideo1, setIsSavingVideo1] = useState(false);
    const [isSavingVideo2, setIsSavingVideo2] = useState(false);
    const [isSavingVideo3, setIsSavingVideo3] = useState(false);
    const [isSavingDp, setIsSavingDp] = useState(false);
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [canEdit, setCanEdit] = useState(true);
    const [canEdit1, setCanEdit1] = useState(true);
    const [canEdit2, setCanEdit2] = useState(true);
    const [canEdit3, setCanEdit3] = useState(true);
    const [activeOffer, setActiveOffer] = useState("");
    // let activeOffer = "";

    const profilePicInputRef = useRef();
    const video1RecorderRef = useRef();
    const video2RecorderRef = useRef();
    const video3RecorderRef = useRef();
    const uploadVideo1Ref = useRef();
    const uploadVideo2Ref = useRef();
    const uploadVideo3Ref = useRef();
    const addPortfolioImageRef = useRef();
    const video1TextRef = useRef();
    const video2TextRef = useRef();
    const video3TextRef = useRef();

    // const location = "New York, NY";

    const [fullName , setFullName] = useState();
    const [position, setPosition] = useState();
    const [openTo, setOpentTo] = useState();
    const [city, setCity] = useState();
    const [state, setState] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [linkedIn, setLinkedIn] = useState();
    const [indeed, setIndeed] = useState();
    const [readOnly, setReadOnly] = useState(false);
    const [username, setUsername] = useState();
    const [userId, setUserId] = useState();
    const [isTelHovered, setIsTelHovered] = useState(false);
    const [autoNavigateToSubsPage, setAutoNavigateToSubsPage] = useState(true);
    const [deviceType, setDeviceType] = useState('Desktop');
    const [enlargeProfile, setEnlargeProfile] = useState(false);
    const [showMenuPopup, setShowMenuPopup] = useState(false);
    const [videoToBePaidFor, setVideoToBePaidFor] = useState(null);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [feedbackType, setFeedbackType] = useState('positive');
    const [sbMessage, setSBMessage] = useState("");
    const [isStarted, setIsStarted] = useState(false);

    const triggerSnackbar = (type, message) => {
    setFeedbackType(type);
    setSBMessage(message);
    setShowSnackbar(true);
    };

    const {user} = useParams();
    localStorage.setItem('autoNavigateToSubsPage', true);

    const navigate = useNavigate();

    // useEffect(()=>{
    //     if(user) {
    //         setReadOnly(true);
    //         console.log("*****READ ONLY*****");
    //     } else {
    //         console.log("****READ AND WRITE****");
    //     }
    //     return () => {};
    // }, []);

    const handleMouseEnter = () =>{
         setIsHovered(true);
         console.log(`is hovered: ${isHovered}, is read only: ${readOnly}, isdesktop: ${deviceType == "Desktop" ? true : false}`)
         console.log(`Show pop up: ${(isHovered && !readOnly && deviceType == "Desktop")}`)
         setShowMenuPopup((!readOnly && deviceType == "Desktop"));
        }
    const handleMouseLeave = () => {
        setShowMenuPopup(false);
    }
    // const foregroundColor = "black";


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isOnboardingDialogOpen, setOnboardingDialogState]  = useState(true);

    const handleOpenDialog = () => setIsDialogOpen(true);
    const handleCloseDialog = () => setIsDialogOpen(false);
    const handleOpenObDialog = () => setOnboardingDialogState(true);
    const handleCloseObDialog = ()  => setOnboardingDialogState(false);
    const handleConfirm = () => {
    //   alert('Action confirmed!');
      setIsDialogOpen(false);
    };

    const detectDevice = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            console.log("***Device is mobile")
          setDeviceType('Mobile');
        } else if (window.matchMedia("(max-width: 1024px)").matches) {
            console.log("***Device is Tablet")
          setDeviceType('Tablet');
        } else {
            console.log("***Device is desktop")
          setDeviceType('Desktop');
        }
      };

    function toggleShowVideo1() {
        const newState = !showVideo1;
        setShowVideo1(newState)
        console.log("Toggling show video 1")
    }

    function uploadVideo1(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo1File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video1RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 1 Url: ${video1Url}}`)
        }
    }

    function toggleShowVideo2() {
        const newState = !showVideo2;
        setShowVideo2(newState)
        console.log("Toggling show video 3")
    }

    function uploadVideo2(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo2File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video2RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 2 Url: ${video2Url}}`)
        }
    }

    function toggleShowVideo3() {
        const newState = !showVideo3;
        setShowVideo3(newState)
        console.log("Toggling show video 3")
    }
    
    function uploadVideo3(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setVideo3File(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const videoUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          video3RecorderRef.current.extSetVideoUrl(videoUrl); // Set preview URL
          console.log(`Video 3 Url: ${video3Url}}`)
        }
    }

    // todo:
    function changeProfilePicture() {
        profilePicInputRef.current.click();
    }

    const handleProfilePicUpload = async (event) => {
        const selectedFile = event.target.files[0]; // Get the selected file
        setProfilePicFile(selectedFile);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const imageUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          setProfilePictureUrl(imageUrl); // Set preview URL

          if(activeOffer == "") {
            // setTimeout(()=>{
            //     navigate("/subscription");
            // }, 500);
            triggerSnackbar("negative", "Your profile photo won't be saved unless you're subscribed")
            return;   
          }

          try {
            const token = localStorage.getItem('token');
            console.log(`Token sentt to server: ${token}`);
            const formData = new FormData();
            formData.append('file', selectedFile)
            // const response = await fetch('http://localhost:5000/api/upload-media/upload-profile-photo', {
            const response = await fetch('https://api.yourtalentid.com/api/upload-media/upload-profile-photo', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
              },
              body: formData,
            });
      
            const result = await response.json();
            if (response.ok) {
              alert('Data saved successfully');
            } else {
              alert(`Error: ${result.error}`);
            }
          } catch (error) {
            console.error('Error saving data:', error);
          }
        }
    }

    async function handlePortfolioMediaUpload(event) {
        const selectedFile = event.target.files[0]; // Get the selected file
        setPortfoliioMediaFiles([...portfolioMediaFiles,selectedFile]);  // Store the file in state
    
        // Create a preview URL for the selected image
        if (selectedFile) {
          const mediaUrl = URL.createObjectURL(selectedFile); // Generate URL for preview
          setPortfolioMediaUrls([...portfolioImageUrls,mediaUrl]); // Set preview URL
          const fileType = selectedFile.type;
          setPortfolioMediaTypes([...portfolioMediaTypes, fileType.split("/")[0] ])
          
            const formData = new FormData();
            formData.append('file', selectedFile);

            if(activeOffer == "") {
                // setTimeout(()=>{
                //     navigate("/subscription");
                // }, 500);
                triggerSnackbar("negative", "Portfolio media can't be saved unless you're subscribed")
                return;   
              }

            try {
             console.log('Uploading...');
             const token = localStorage.getItem('token');
             const response = await fetch('https://api.yourtalentid.com/api/upload-media/upload-portfolio-media', {
            //  const response = await fetch('http://localhost:5000/api/upload-media/upload-portfolio-media', {
    
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
                body: formData,
            });
        
            if (response.ok) {
                console.log('Upload successful!');
                const data = await response.json();
                // videoTextRef.current.value = "hello world";
                console.log(data)
            } else {
                console.log(`Upload failed: ${response.statusText}`);
            }
            } catch (error) {
                console.log(`Upload error: ${error.message}`);
            }
            
        }
    }

    async function handlePortfolioMediaDelete() {
        const indexToDelete = selectedImageId;
        console.log(`Deleting: ${indexToDelete}`);
        setPortfolioMediaUrls([...portfolioImageUrls.slice(0, indexToDelete), ...portfolioImageUrls.slice(indexToDelete + 1)]);
        setPortfoliioMediaFiles([...portfolioMediaFiles.slice(0, indexToDelete), ...portfolioMediaFiles.slice(indexToDelete + 1)]);

        const formData = new FormData();
            formData.append('indexToDelete', indexToDelete);
            try {
             console.log('Uploading...');
             const token = localStorage.getItem('token');
             const response = await fetch('https://api.yourtalentid.com/api/user/delete-portfolio-media', {
            //  const response = await fetch('http://localhost:5000/api/user/delete-portfolio-media', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
                body: formData,
            });
        
            if (response.ok) {
                console.log('Upload successful!');
                const data = await response.json();
                // videoTextRef.current.value = "hello world";
                console.log(data)
                window.location.reload();
            } else {
                console.log(`Upload failed: ${response.statusText}`);
            }
            } catch (error) {
                console.log(`Upload error: ${error.message}`);
            }


    }

    const handleShare = async () => {
        if(activeOffer == "") {
            // setTimeout(()=>{
            //     navigate("/subscription");
            // }, 500);
            triggerSnackbar("negative", "You can't copy or share your profile link unless you're subscribed")
            return;   
        }
        
        const shareData = {
          title: 'Check this out!',
          text: '',
          url: shareableUrl + `${userId}`,
        };
    
        try {
          await navigator.share(shareData);
          console.log('Content shared successfully');
        } catch (err) {
          console.log('Error sharing:', err);
        }
      };


    const handleVideoDelete = async (videoName) => {
        try {
          const formData = new FormData();
          formData.append('videoName', videoName)
          const token = localStorage.getItem('token');
          const response = await fetch('https://api.yourtalentid.com/api/user/delete-video', {
            // const response = await fetch('http://localhost:5000/api/user/delete-video', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
            body: formData,
          });
    
          if (response.ok) {
            console.log('Delete request successful!');
            // const data = await response.json();
            // videoTextRef.current.value = "hello world";
            window.location.reload();
          } else {
            console.log(`Delete request failed: ${response.statusText}`);
          }

            
        } catch (error) {
            console.log("Error deleting video", error);
            
        }
    }

    function getUserDetails() {
    }

    const saveData = async(e) => {
        console.log("Active offer state: ", activeOffer)

        if(activeOffer == "") {
            setTimeout(()=>{
                navigate("/subscription");
            }, 500);
            return;   
        }

        console.log("Video 1 transcripttion: " + video1Transcription)
        setIsSavingFormData(true);
        try {
            console.log('Saving data');
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('city', city);
            formData.append('state', state);
            formData.append('position', position);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('linkedIn', linkedIn);
            formData.append('indeed', indeed);
            formData.append('video1_transcription', video1Transcription);
            formData.append('video2_transcription', video2Transcription);
            formData.append('video3_transcription', video3Transcription);

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
              }
            const response = await fetch('https://api.yourtalentid.com/api/user/save-data', {
            // const response = await fetch('http://localhost:5000/api/user/save-data', {
              method: 'POST',
              headers: {
                  'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
              },
              body: formData,
            });
      
            if (response.ok) {
              console.log('save successful!');
              const data = await response.json();
              // videoTextRef.current.value = "hello world";
              console.log(data.message)
              triggerSnackbar("positive", "Data successfully saved!")
            } else {
              console.log(`Upload failed: ${response.statusText}`);
              triggerSnackbar("negative", "Oops! Something went wrong. Failed to save data")
            }
            setIsSavingFormData(false);
  
    
          } catch (error) {
            console.log(`Upload error: ${error.message}`);
            triggerSnackbar("Oops! Something went wrong. Failed to save data.")
          }

    }
 
    // Function to copy text to clipboard
    const copyTextToClipboard = async () => {
        if(activeOffer == "") {
            // setTimeout(()=>{
            //     navigate("/subscription");
            // }, 500);
            triggerSnackbar("negative", "You can't copy or share your profile link unless you're subscribed")
            return;   
        }
        try {
        await navigator.clipboard.writeText(shareableUrl + `${userId}`); // Use Clipboard API to copy text
        //   setCopySuccess('Text copied to clipboard!'); // Update success message
        triggerSnackbar("positive", "Link copied.");
        } catch (err) {
        //   setCopySuccess('Failed to copy text.'); // Handle error
        triggerSnackbar("negative", "Failed to copy link.");
        }
    };


    const handleOutsideClick = (event) => {
        console.log("Clicked out");
        setIsTelHovered(false);
        setEnlargeProfile(false);

      };
  
    // Periodically check if the authentication token hasn't expired yet!
    useEffect(user ? () => {} : () => {
        let intervalId;
        const checkTokenExpiration = () => {
            console.log("***Checking token expiration***")
            const tokenCreated = localStorage.getItem('token_created');

            if (tokenCreated) {
                const tokenAge = Date.now() - parseInt(tokenCreated); // Calculate the time since the token was created
                const tokenExpirationTime = 55 * 60 * 1000; // 1 hour in milliseconds

                if (tokenAge > tokenExpirationTime) {
                    triggerSnackbar("negative", "Your session has expired. You'll be logged out!")
                    localStorage.removeItem('token_created'); // Optionally remove the expired token
                    const data = { page: "signIn" }

                    setInterval(() => {
                        navigate('/auth', { state: data });
                        window.location.reload();
                    }, 5000);
                    
                } else {
                    // setMessage(`Token is valid. Time left: ${(tokenExpirationTime - tokenAge) / 1000}s`);
                }
            } else {
                const data = { page: "signIn" }
                    navigate('/auth', { state: data });
                    window.location.reload();
                // setMessage('No token found.');
            }
        };
   
        // executePeriodicFunction(); // Run once immediately
        checkTokenExpiration();
        intervalId = setInterval(() => {
            checkTokenExpiration();;
        }, 1000); // Executes every 5 seconds
      
  
        return () => {
            clearInterval(intervalId); // Cleanup on unmount or when `isStarted` changes
        };
    }, []);


    //To be executed on first launch
    useEffect( () => {
        document.addEventListener('mousedown', handleOutsideClick);
        detectDevice(); // Run on initial load
        window.addEventListener('resize', detectDevice); 
        console.log("First time execution");
        if(user) {
            setReadOnly(true);
            console.log("*****READ ONLY*****");
        } else {
            setReadOnly(false)
            console.log("****READ AND WRITE****");
            // const autoNavigate = localStorage.getItem("just_signed_up")
            // console.log(`Should auto navigate: ${autoNavigate}, Will navigate: ${autoNavigate}`)
            // if(autoNavigate == true) {
            //     // setAutoNavigateToSubsPage(false);
            //     console.log("Trying to navigate");
            //     localStorage.setItem("just_signed_up", false);
            //     autoNavigate = localStorage.getItem("just_signed_up")
            //     setTimeout(()=>{
            //         navigate("/subscription")
            //     }, 3000)
                
            // }
        }

        getData();

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            window.removeEventListener('resize', detectDevice); 
            console.log("Cleaning up...");
        
            // Ensure proper cleanup of video recorder refs
            if (video1RecorderRef.current?.destroy) {
              video1RecorderRef.current.destroy();
            }
            if (video2RecorderRef.current?.destroy) {
              video2RecorderRef.current.destroy();
            }
            if (video3RecorderRef.current?.destroy) {
              video3RecorderRef.current.destroy();
            }
          };

    }, []);


    const payForEdit = async() => {
        // setLoader(true);
        const offerId = 2; //$10 payment
        const feedbackId = generateRandomString();
        localStorage.setItem("feedbackId", feedbackId);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('https://api.yourtalentid.com/api/payment/create-checkout-session', {
          // const response = await fetch('http://localhost:5000/api/payment/create-checkout-session', {  
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, 
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({offerId, videoToBePaidFor, feedbackId}),
          });
    
    
          try {
            const data = await response.json();
      
            // Create a Blob from the HTML content
            // const blob = new Blob([htmlContent], { type: 'text/html' });
            // const url = URL.createObjectURL(blob);
      
            // // Open the Blob URL in a new tab
            // window.open(url, '_blank');

            if (data.url) {
                console.log("Data url: ", data.url)
                window.location.href = data.url; // Redirect to Stripe Checkout
              } else {
                console.error('Failed to create Stripe session');
              }
            
            // Optionally, revoke the object URL after a timeout to free up memory
            // setTimeout(() => URL.revokeObjectURL(url), 1000);
          } catch (error) {
            console.error('Error opening checkout:', error);
          }
    
        }catch(e) {
          console.log(`Error: ${e.message}`);
        }
        // setLoader(false);
      };


    const transcribeVideo = async (videoFile, setVideoTranscript, setLoader, videoName="video1", saveOnly=false) => {

        console.log(`Type of saveOnly: ${typeof(saveOnly)}`)
        if(activeOffer == "") {
            setTimeout(()=>{
                navigate("/subscription");
            }, 500);
            return;   
        }
        // console.log(`Save Only State: ${saveOnly}`)

        // console.log(`Type of saveOnly: ${typeof(saveOnly)}`)

        console.log(`Testing can edit in transcription function: ${canEdit}`)
        if (saveOnly === false) {
            if(canEdit1 == false && videoName == "video1") {
                setVideoToBePaidFor("1")
                handleOpenDialog(setLoader);
                return;
            }
            if(canEdit2 == false && videoName == "video2") {
                setVideoToBePaidFor("2")
                handleOpenDialog(setLoader);
                return;
            }
            if(canEdit3 == false && videoName == "video3") {
                setVideoToBePaidFor("3")
                handleOpenDialog(setLoader);
                return;
            } 
        }
        
        if(videoFile) {
    
        }else {
          console.log("No video file")
          return;
        }
        const formData = new FormData();
        formData.append('file', videoFile);
        // formData.append('data', JSON.stringify({videoName, saveOnly}))
        formData.append('videoName', videoName)
        formData.append('saveOnly', saveOnly);
        setLoader(true);
        try {
          console.log('Uploading...');
          const token = localStorage.getItem('token');
          const response = await fetch('https://api.yourtalentid.com/api/transcribe', {
        //  const response = await fetch('http://localhost:5000/api/transcribe', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
            body: formData,
          });
    
          if (response.ok) {
            console.log('Upload successful!');
            const data = await response.json();
            // videoTextRef.current.value = "hello world";
            if(saveOnly === false) {
                setVideoTranscript(data.transcription);
            }
            console.log(data.transcription)
          } else {
            console.log(`Upload failed: ${response.statusText}`);
          }
          setLoader(false);
        } catch (error) {
          console.log(`Upload error: ${error.message}`);
        }
      };


    const downloadResume = async () => {
        try {

            const token = localStorage.getItem('token');
            fetch(`https://api.yourtalentid.com/api/download/generate-resume`, {
            // fetch(`http://localhost:5000/api/download/generate-resume`, {
                method: 'GET', // or 'POST', etc.
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    'Content-Type': 'application/json'
                }
            }
            )
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(userData => {
                if(!userData) {
                    console.log("***SERVER DATA IS NULL***");
                }
                console.log("Successfully got download url: ", userData.downloadUrl);
                const link = document.createElement('a');
                link.href = userData.downloadUrl;
                link.download = '';  // Optional: Provide a default filename here
                link.click();

            })
            .catch(err => {
                console.log("Failed to fetch user data", err);
                // setError("Error fetching data"); // Uncomment if needed
                // setLoading(false); // Uncomment if needed
            });
            
            
        }catch(error) {
            console.log(`Error: `, error.message)
        }
    }


    const getData = async () => {
        const token = localStorage.getItem('token');

        fetch(`https://api.yourtalentid.com/api/user/get-data${user ? `?user=${user}` : ""}`, {
        // fetch(`http://localhost:5000/api/user/get-data${user ? `?user=${user}` : ""}`, {

            method: 'GET', // or 'POST', etc.
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                'Content-Type': 'application/json'
            }
        }
        )
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(userData => {

            if(!userData) {
                console.log("***USER DATA IS NULL***");
            }
            console.log("Successfully got user-data");
            // setFullName(userData.name); // Uncomment if needed
            setProfilePictureUrl(userData.dp_url); //

            if(video1RecorderRef.current) {
                video1RecorderRef.current.extSetVideoUrl(userData.video1_url)
                setVideo1Transcription(userData.video1_transcription??"")
            }

            if(video2RecorderRef.current) {
                video2RecorderRef.current.extSetVideoUrl(userData.video2_url)
                setVideo2Transcription(userData.video2_transcription??"")
            }

            if(video3RecorderRef.current) {
                video3RecorderRef.current.extSetVideoUrl(userData.video3_url)
                setVideo3Transcription(userData.video3_transcription??"")
            }
            
            // Pull user data on first launch
            setFullName(`${userData.first_name} ${userData.last_name}`);
            setPosition(userData.position??"");
            setCity(userData.city??"");
            setState(userData.state??"");
            setPhone(userData.phone??"");
            setEmail(userData.email??"");
            setLinkedIn(userData.linkedIn??"");
            setIndeed(userData.indeed??"");
            // setUsername(userData.username??"")
            setUserId(userData.uid??"");
            setPortfolioMediaUrls(userData.portfolio_media_urls??[])
            setPortfolioMediaTypes(userData.portfolio_media_types??[]);
            setActiveOffer(userData.active_offer ?? "")
            // activeOffer = userData.active_offer ?? "";
            console.log(`Active Offer: ${activeOffer}`);
            if(user) {
                // setReadOnly(true);
                console.log("*****READ ONLY*****");
            } else {
                console.log(`Read Only State: ${readOnly}`)
                // if(activeOffer == "") {
                //     setTimeout(()=>{
                //         navigate("/subscription");
                //     }, 2000);   
                // }
            }
           

            const canEdit1 = userData.can_edit_1??false;
            const canEdit2 = userData.can_edit_2??false;
            const canEdit3 = userData.can_edit_3??false;


           setCanEdit(canEdit1 == true || canEdit2 == true || canEdit3 == true);
           setCanEdit1(canEdit1);
           setCanEdit2(canEdit2);
           setCanEdit3(canEdit3);
           console.log(`Can edit: ${canEdit1 == true || canEdit2 == true || canEdit3 == true}`);
           console.log(`Can edit: ${canEdit}`);
            // setData(userData); // Uncomment if needed
            // setLoading(false); // Uncomment if needed
        })
        .catch(err => {
            console.log("Failed to fetch user data", err);
            // setError("Error fetching data"); // Uncomment if needed
            // setLoading(false); // Uncomment if needed
        });
    }


    return <ErrorBoundary>
    <div style={{backgroundColor: backgroundColor??"white"}}>
    {showSnackbar && (
      <Snackbar
        message={sbMessage}
        type={feedbackType}
        onClose={() => setShowSnackbar(false)}
      />
    )}

    {/* Pay for editing the transcription */}
    <DialogBox
        isOpen={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={payForEdit}
        text="Are you sure you want to transcribe and save this video? After transcription, any re-recording will cost an additional fee"
        okText="Ok"
        cancelText="Cancel"
      />

    <DialogBox
        isOpen={!readOnly && isOnboardingDialogOpen}
        onClose={handleCloseObDialog}
        onConfirm={handleCloseObDialog}
        text={<>Welcome to TalentID Profile Creation! <br/><br/>

        You're just a few steps away from building a standout digital resume.<br/>
        
        Follow these simple steps:<br/><br/><br/>
        
        1. Add Your Basic Information – Complete your name, location, contact details and add your profile photo (optional).<br/><br/>
        
        
        2. Record or Upload Your Video Responses – Answer the key questions to help recruiters get to know you. Once you're happy with the uploaded video, tap on the "Save and Transcribe" button or else your changes will be lost. (ios users must click on upload icon to record or upload video)<br/><br/>
        
        
        3. Upload Your Portfolio (Optional) – Showcase your best work to impress potential employers.<br/><br/>
        
        
        4. Link Your LinkedIn and Indeed Profiles (Optional) – Make it easy for recruiters to explore more about you.<br/><br/><br/>
        
        Note: For better audio quality, please upload a pre-recorded video while we work on improving the quality of our audio recorder.
        
        Take your time to create a profile that reflects your strengths. Remember, you can come back and update any of these sections later.<br/><br/>
        
        Let’s get started!</>}
        okText="Got it!"
        cancelText=""
      />

{/* header with color palette */}
<div style={{position: "sticky", top: "0px", zIndex: "1000", backgroundColor: backgroundColor??"white", padding: "20px"}}>

<div style={{display: 'flex', justifyContent: 'start'}}>
        {!readOnly && <DrawerMenu/>}
        <Logo/>
    </div>

    {/* <div style={{display: readOnly ? "none" : "flex", position: "relative", justifyContent: "end"}}>
        <CustomColorPalette onColorChange={(newColor) => setBackgroundColor(newColor)} title="Background"/>
        <div style={{width: "40px"}}/>
        <CustomColorPalette onColorChange={(newColor) => setForegroundColor(newColor)} title='Foreground'/>
    </div> */}
</div>

<div style={{ display: "flex", borderRadius: "20", justifyContent: "center", margin: "20px"}}>

<div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>

{/* Element that appears when hovered */}
{showMenuPopup && (
    <div style={{position: "relative", zIndex: "1000"}}>
        <MorePopMenu/>
    </div>
)}

<div onClick={readOnly == true ? () => {setEnlargeProfile(true)} : changeProfilePicture} style={{display: "inline"}}>
{(profilePicUrl != null) ? <img src={profilePicUrl} height={enlargeProfile ? "200" : "100"} width={enlargeProfile ? "200" : "100" }style={{ borderRadius: "50%", objectFit: "cover" }} />
    : <FontAwesomeIcon icon={faUserReg} size={enlargeProfile ? "10x" : "5x"} height="100" width="100" style={{ borderRadius: "50%", objectFit: "cover" }}/>}
</div>

<input type="file" onChange={handleProfilePicUpload} multiple ref={profilePicInputRef} style={{display: "none"}}/>

</div>

<div style={{ marginLeft: "10px" }}>
    <h5 style={{ margin: "0px" }}>{fullName}</h5>
    <h7 style={{ margin: "0px" }}>{position}</h7>
    <div style={{ display: "flex", alignItems: "baseline" }}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="location-icon"
        >
            <path d="M21 10c0 7-9 13-9 13S3 17 3 10a9 9 0 0 1 18 0z" />
            <circle cx="12" cy="10" r="3" />
        </svg>
        <p>{`${city??""}, ${state??""}`}</p>

    </div>
    {isTelHovered && <div style={{zIndex: 10000}}>{phone}</div>}
    <div style={{display: "flex", alignItems: "center" ,justifyContent: "space-evenly", backgroundColor: 'lightgray', height: '30px', borderRadius: "15px" }}>
        {indeed && <a href={indeed} target="_blank" className="social">
                <img src={indeedLogo} color="black"/>
        </a>}

        {email && <a href={"mailto:" + email} target="_blank" className="social">
            <i class="fas fa-envelope"></i>
        </a>}

        {phone && <div onClick={deviceType == "Desktop" ? ()=>{setIsTelHovered(true)} : () => {}} >
        {/* href={"tel:" + phone} */}
        
            { deviceType == "Desktop" 
            ? <a target="_blank" className="social"> <i class="fas fa-phone"></i> </a>
            : <a href={"tel:" + phone} target="_blank" className="social"> <i class="fas fa-phone"></i> </a> }
        
            
        </div>}

        {linkedIn && <a href={linkedIn} target="_blank" className="social">
            <i className="fab fa-linkedin-in" />
        </a>}
    </div>
    {/* Availability container */}
    {/* <div className="hide-on-mobile" style={{display: "flex", alignItems: "center"}}>
        <p style={{paddingRight: "10px", alignItems: "center"}}>Open to</p>
        <p style={{ backgroundColor: "green", color: "white", padding: "10px", borderRadius: "30px", }}>{openTo}</p>
    </div>  */}
</div>
</div>


{/* copy shareable link */}
<div style={{display: readOnly ? "none" : "flex", alignItems: "center", backgroundColor: foregroundColor, height: '50px', width: '300px', borderRadius: "15px" , margin: "0 auto"}}>
            <div style={{width: "80%", marginLeft: "10px", overflow: "auto"}}>
            <h3 style={{overflow: "hidden", textOverflow: "ellipsis" , color: "white", whiteSpace: "nowrap"}}>{shareableUrl + `${userId}`}</h3>
            </div>
            <div onClick={copyTextToClipboard} title="copy" style={{ display: "flex", alignItems: "center" ,position: "relative", width: "20%" , height: "100%"}}>
                    <div style={{position: "absolute" , right: "10px", margin: "auto 0"}}>
                        <i class="fa-solid fa-copy fa-xl" style={{color: backgroundColor, opacity: 1}}></i>
                    </div>
            </div>
            <div onClick={handleShare} title="copy" style={{ display: "flex", alignItems: "center" ,position: "relative", width: "20%" , height: "100%"}}>
                    <div style={{position: "absolute" , right: "10px", margin: "auto 0"}}>
                        <i class="fa-solid fa-share fa-xl" style={{color: backgroundColor, opacity: 1}}></i>
                    </div>
            </div>
</div>


{/* Actions */}
{/* <div style={{display: readOnly ? "none" : "flex", justifyContent: "center", alignItems: "center", marginTop: "20px"}}>
            <div onClick={handleShare} style={{display: "inline"}}>
                <FontAwesomeIcon icon={faShareSquare} size='2x' style={{color: foregroundColor}}/>
            </div>
            <div style={{width: "5%"}}/>
</div> */}


{/* Video transcript box 1*/}
<div className='video-transcript-container'>
<div className='header' style={{backgroundColor: foregroundColor, padding: "15px 15px"}}>
<div className='multiline-ellipsis'>
    <h2 style={{textAlign: "start"}}>Tell us more about yourself</h2>
    <p style={{display: readOnly ? "none" : "", padding: "0", color: "white", opacity: "0.7"}}>*(2 minutes Max)</p>
</div>
<div  className='actions' style={{display: "flex" ,alignItems: "center"}}>

        {/* delete video - if published */}
        <div style={{display: readOnly ? "none" : "inline"}} onClick={()=>{handleVideoDelete("video1")}}>
            <i class="fas fa-trash fa-2x" style={{margin: "0px 10px"}}></i>
        </div>

        {/* upload video */}
        <div style={{display: readOnly ? "none" : "flex"}} onClick={()=>{uploadVideo1Ref.current.click()}}>
            <i class="fa-solid fa-upload fa-2xl"></i>
        </div>
        <input type="file" onChange={uploadVideo1} multiple ref={uploadVideo1Ref} style={{display: "none"}}/>

        {/* hide/show video */}
        <div onClick={toggleShowVideo1} style={{ display: 'inline' }}>

            {
                showVideo1
                    ? <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                            stroke="white"
                            stroke-width="2"
                            fill="none"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            r="3"
                            stroke="white"
                            stroke-width="2"
                            fill="none"
                        />
                        <path
                            d="M3 3L21 21"
                            stroke="white"
                            stroke-width="2"
                            fill="none"
                        />
                    </svg>
                    : <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                            stroke="white"
                            stroke-width="2"
                            fill="none"
                        />
                        <circle
                            cx="12"
                            cy="12"
                            r="3"
                            stroke="white"
                            stroke-width="2"
                            fill="none"
                        />
                    </svg>
            }



        </div>
</div>
</div>
<div style={{display: showVideo1 ? "block" : "none"}} >
    <VideoRecorder readOnly={readOnly} videoFile={video1File} setVideoFile={setVideo1File} foregroundColor={foregroundColor} ref={video1RecorderRef}/>
        {/* Transcribe button */}
        <div style={{display: readOnly ? "none" : "flex", justifyContent: "center", padding: "10px 0px"}}>
            <button onClick={() => {transcribeVideo(video1File, setVideo1Transcription, setIsSavingVideo1, "video1", true)}} style={{backgroundColor: foregroundColor, display: "flex"}}>
                <div>Save Video</div>
                <Loader isLoading={isSavingVideo1} />
                </button>
        </div>
        <div style={{display: readOnly ? "none" : "flex", justifyContent: "center"}}>
            <button onClick={() => {transcribeVideo(video1File, setVideo1Transcription, setIsPublishingVideo1, "video1")}} style={{backgroundColor: foregroundColor, display: "flex"}}>
                <div>Save & Transcribe</div>
                <Loader isLoading={isPublishingVideo1} />
                </button>
        </div>
</div>

<div style={{color: "black", height: '200px', overflow: "hidden"}}> 
    <textarea readOnly={readOnly} style={{color: "black"}} value={video1Transcription} ref={video1TextRef} placeholder="The Video transcript will appear here" onChange={(event)=>{setVideo1Transcription(event.target.value)}} >
        
    </textarea>

{/*<input  
    value="I am a motivated individual with a strong passion for artificial intelligence 
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."

        />*/}
</div>

</div>

{/* Video transcript box 2*/}
<div className='video-transcript-container'>
    <div className='header' style={{ backgroundColor: foregroundColor, padding: "15px 15px" }}>
    <div className='multiline-ellipsis'>
        <h2 style={{textAlign: "start"}} >Tell us about your work history</h2>
        <p style={{display: readOnly ? "none" : "", padding: "0", color: "white", opacity: "0.7"}}>*(2 minutes Max)</p>
    </div>
        <div className='actions' style={{ display: "flex", alignItems: "center" }}>
            {/* delete video - if published */}
            <div style={{display: readOnly ? "none" : "inline"}} onClick={()=>{handleVideoDelete("video2")}}>
                <i class="fas fa-trash fa-2x" style={{margin: "0px 10px"}}></i>
            </div>
            <div style={{display: readOnly ? "none" : "block"}} onClick={() => { uploadVideo2Ref.current.click() }}>
                <i class="fa-solid fa-upload fa-2xl"></i>
            </div>
            <input type="file" onChange={uploadVideo2} multiple ref={uploadVideo2Ref} style={{ display: "none" }} />

            <div onClick={toggleShowVideo2} style={{ display: 'inline' }}>

                {
                    showVideo2
                        ? <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <circle
                                cx="12"
                                cy="12"
                                r="3"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <path
                                d="M3 3L21 21"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                        </svg>
                        : <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <circle
                                cx="12"
                                cy="12"
                                r="3"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                        </svg>
                }



            </div>
        </div>
    </div>
    <div style={{ display: showVideo2 ? "block" : "none" }} >
        <VideoRecorder readOnly={readOnly} foregroundColor={foregroundColor} videoFile={video2File} setVideoFile={setVideo2File} ref={video2RecorderRef} />
        
        {/* Save button */}
        <div style={{display: readOnly ? "none" : "flex", justifyContent: "center", padding: "10px 0px"}}>
            <button onClick={() => {transcribeVideo(video2File, setVideo2Transcription, setIsSavingVideo2, "video2", true)}} style={{backgroundColor: foregroundColor, display: "flex"}}>
                <div>Save Video</div>
                <Loader isLoading={isSavingVideo2} />
                </button>
        </div>

        {/* Transcribe button */}
        <div style={{display: readOnly ? "none" : "flex", justifyContent: "center" }}>
            <button style={{backgroundColor: foregroundColor, display: "flex"}} onClick={() => { transcribeVideo(video2File, setVideo2Transcription, setIsPublishingVideo2, "video2") }}>
                    <div>Save & Transcribe</div>
                    <Loader isLoading={isPublishingVideo2} />
                </button>
        </div>
    </div>

    <div style={{ height: '200px', overflow: "hidden" }}>
        <textarea readOnly={readOnly} style={{color: "black"}} value={video2Transcription} ref={video2TextRef} placeholder="The Video transcript will appear here" onChange={(event) => { setVideo2Transcription(event.target.value) }} >

        </textarea>

        {/*<input  
        value="I am a motivated individual with a strong passion for artificial intelligence 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."
    
            />*/}
    </div>

</div>

{/* Video transcript box 3*/}
<div className='video-transcript-container'>
    <div className='header' style={{ backgroundColor: foregroundColor, padding: "15px 15px"}}>
    <div className='multiline-ellipsis'>
        <h2 style={{textAlign: "start"}}>Tell us about your educational background</h2>
        <p style={{display: readOnly ? "none" : "", padding: "0", color: "white", opacity: "0.7"}}>*(2 minutes Max)</p>
    </div>
        <div className='actions' style={{ display: "flex", alignItems: "center" }}>
             {/* delete video - if published */}
             <div style={{display: readOnly ? "none" : "inline"}} onClick={()=>{handleVideoDelete("video3")}}>
                <i class="fas fa-trash fa-2x" style={{margin: "0px 10px"}}></i>
            </div>               
            <div style={{display: readOnly ? "none" : "flex"}} onClick={() => { uploadVideo3Ref.current.click() }}>
                <i class="fa-solid fa-upload fa-2xl"></i>
            </div>
            <input type="file" onChange={uploadVideo3} multiple ref={uploadVideo3Ref} style={{ display: "none" }} />


            <div onClick={toggleShowVideo3} style={{ display: 'inline' }}>

                {
                    showVideo3
                        ? <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <circle
                                cx="12"
                                cy="12"
                                r="3"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <path
                                d="M3 3L21 21"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                        </svg>
                        : <svg
                            width="30"
                            height="30"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 12C2.8 7.3 7 4 12 4s9.2 3.3 11 8c-1.8 4.7-6 8-11 8s-9.2-3.3-11-8z"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                            <circle
                                cx="12"
                                cy="12"
                                r="3"
                                stroke="white"
                                stroke-width="2"
                                fill="none"
                            />
                        </svg>
                }



            </div>
        </div>
    </div>
    <div style={{ display: showVideo3 ? "block" : "none" }} >
        <VideoRecorder readOnly={readOnly} foregroundColor={foregroundColor} videoFile={video3File} setVideoFile={setVideo3File} ref={video3RecorderRef} />
        
                {/* Save button */}
                    <div style={{ display: readOnly ? "none" : "flex", justifyContent: "center", padding: "10px 0px" }}>
                        <button onClick={() => { transcribeVideo(video3File, setVideo3Transcription, setIsSavingVideo3, "video3", true) }} style={{ backgroundColor: foregroundColor, display: "flex" }}>
                            <div>Save Video</div>
                            <Loader isLoading={isSavingVideo3} />
                        </button>
                    </div>
        
        {/* Transcribe button */}
        <div style={{ display: readOnly ? "none" : "flex", justifyContent: "center" }}>
            <button onClick={() => { transcribeVideo(video3File, setVideo3Transcription, setIsPublishingVideo3, "video3") }} style={{ backgroundColor: foregroundColor, display: "flex" }}>
                <div>Save & Transcribe</div>
                <Loader isLoading={isPublishingVideo3} />
            </button>
        </div>
    </div>

    <div style={{ height: '200px', overflow: "hidden" }}>
        <textarea readOnly={readOnly} style={{color: "black"}} value={video3Transcription} ref={video3TextRef} placeholder="The Video transcript will appear here" onChange={(event) => { setVideo3Transcription(event.target.value) }} >

        </textarea>

        {/*<input  
        value="I am a motivated individual with a strong passion for artificial intelligence 
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum....."
    
            />*/}
    </div>

</div>

<div style={{height: "30px"}}></div>

 {/* Input fields */}
 <div style={{display: readOnly ? "none" : "block", margin: "10px auto", width: "90%"}}>
    
    <input
    type="text"
    placeholder="City"
    name="city"
    onChange={(event)=>{setCity(event.target.value)}}
    value={city}
    />

    <input
    type="text"
    placeholder="State"
    name="state"
    onChange={(event)=>{setState(event.target.value)}}
    value={state}
    />

    <input
    type="text"
    placeholder="Position"
    name="position"
    onChange={(event)=>{setPosition(event.target.value)}}
    value={position}
    />

    <input
    type="tel"
    placeholder="Phone"
    name="phone"
    onChange={(event)=>{setPhone(event.target.value)}}
    value={phone}

    />

    <input
    type="email"
    placeholder="Email"
    name="email"
    onChange={(event)=>{setEmail(event.target.value)}}
    value={email}
    />
    <span style={{color: "red"}}> (Optional)</span>
    <input
    type="url"
    placeholder="LinkedIn Profile"
    name="linkedIn"
    onChange={(event)=>{setLinkedIn(event.target.value)}}
    value={linkedIn}
    />

    <span style={{color: "red"}}> (Optional)</span>
    <input
    type="url"
    placeholder="Indeed Profile"
    name="indeed"
    onChange={(event)=>{setIndeed(event.target.value)}}
    value={indeed}
    />
 </div>

{/* Save button */}
<div style={{display: readOnly ? "none" : "flex"}}>
<div style={{margin: "0px auto"}}>
<button type="submit" onClick={saveData} style={{backgroundColor: foregroundColor, display: "flex"}}>
    <div>Save</div>
    <Loader isLoading={isSavingFormData}/>
 </button>
</div>
</div>

{/* Portfolio Section */}
<div className='video-transcript-container' style={{display:(portfolioImageUrls.length == 0 && readOnly) ? "none" : "" , marginBottom: "30px"}}>
    <div className='header' style={{backgroundColor: foregroundColor, padding: "15px 15px"}}>
    <div className="multiline-ellipsis">
        <h2 style={{textAlign: "start"}}>Portfolio</h2>
        <p style={{display: readOnly ? "none" : "", padding: "0", color: "white", opacity: "0.7"}}>*(Your media upload limit is 2 GB)</p>
    </div>
    <div  style={{display: readOnly ? "none" : "flex"}} className='actions'>
        <div style={{display: "inline"}} onClick={handlePortfolioMediaDelete}>
            <i class="fas fa-trash fa-2x" style={{margin: "0px 10px"}}></i>
        </div>
        
        <div style={{display: "inline"}} onClick={()=>{addPortfolioImageRef.current.click()}}>
            <i class="fas fa-plus fa-2x" style={{margin: "0px 10px"}}></i>
        </div>
        
        <input type='file' onChange={handlePortfolioMediaUpload}  style={{display: "none"}} ref={addPortfolioImageRef}/>
    </div>
    </div>
    <PhotoGallery imageUrls={portfolioImageUrls} mediaTypes={portfolioMediaTypes} setSelectedImageId={(id)=>{setSelectedImageId(id)}}/>
    
 </div>

</div>
    </ErrorBoundary>;
}

export default ProfilePage;