import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <Container>
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Pricing</h1>
            {/* <p className="font13">
              Take a look at our comprehensive pricing plan.
              <br />
            </p> */}
          </HeaderInfo>
          <TablesWrapper>
            <TableBox>
              <PricingTable
                icon=""
                price="$19.99"
                title="Primary Plan"
                text="Try our product free of charge for the first 7 days, then pay only $19.99 to continue using it!"
                offers={[
                  { name: "Edit All 3 Videos", cheked: true },
                  { name: "Edit Profile Data", cheked: true },
                  { name: "Change Profile Picture", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                  { name: "Edit portfolio", cheked: true },
                ]}
                action={() => {}}
              />
            </TableBox>
            {/* Add more TableBox components here if needed */}
          </TablesWrapper>
        </Container>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;

  h1 {
    font-size: 40px;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    margin-top: 10px;
  }
`;

const TablesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 860px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const TableBox = styled.div`
  width: 100%;
  max-width: 370px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
