import React, { useState, useEffect } from 'react';
import './subscription.css'; // Import CSS for styling
import SubscriptionCard from './subscription_card';
import Loader from '../loader';
import XButton from '../x_button';
import PricingCard from './pricing_card';

function generateRandomString() {
  return Math.random().toString(36).substr(2, 10);
}

const SubscriptionScreen = () => {

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('Premium');
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [canTry, setCanTry] = useState(true);
  const [trialEndDate, setTrialEndDate] = useState("");
 const [transactions, setTransactions] = useState([]);

  // isSubscribed = false, 
  // currentPlan = 'Premium', 
  // subscriptionDate = '2024-09-15', 
  // expirationDate = '2024-10-30', 
  // transactions = [] 

  useEffect(() => {
        const token = localStorage.getItem('token');

        fetch(`https://api.yourtalentid.com/api/user/get-data`, {
            method: 'GET', // or 'POST', etc.
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(userData => {

            if(!userData) {
                console.log("***USER DATA IS NULL***");
            }

            console.log("Successfully got user-data");
            if(userData.active_offer != null && userData.active_offer != "") {
              const subsDate = new Date(userData.subs_start);
              const expDate= new Date(userData.subs_end);
              console.log(`${userData.subs_start}`)
              setIsSubscribed(true);
              console.log(`Subscription state: ${isSubscribed}`)
              setCurrentPlan(userData.active_offer);
              setSubscriptionDate(subsDate.toLocaleDateString());
              setExpirationDate(expDate.toLocaleDateString());
            }

            console.log(`can try: ${userData.canTry}`)
            const _canTry = typeof userData.canTry === "undefined" || userData.canTry === null || userData.canTry === "" || userData.canTry === true ;
            setCanTry(_canTry);
            console.log(`Can try state: ${_canTry}`)

            // setFullName(userData.name); // Uncomment if needed

        }).catch(err => {
            console.log("Failed to fetch user data", err);
            // setError("Error fetching data"); // Uncomment if needed
            // setLoading(false); // Uncomment if needed
        });

  }, []);

  const handleCancel = async() => {
    setIsCancelling(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.yourtalentid.com/api/payment/cancel-subscription', {
      // const response = await fetch('http://localhost:5000/api/payment/cancel-subscription', {  
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`, 
          // 'Content-Type': 'application/json',
        },
      });

    }catch(e) {
      console.log(`Error: ${e.message}`);
    }
    setIsCancelling(false);
  };

  const handleSubscribe = async() => {
    // const userTriggeredOpen = window.open('', '_blank');
    setIsSubscribing(true);
    const feedbackId = generateRandomString();
    localStorage.setItem("feedbackId", feedbackId);
    const offerId = 0;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.yourtalentid.com/api/payment/create-checkout-session', {
      // const response = await fetch('http://localhost:5000/api/payment/create-checkout-session', {  
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({offerId, feedbackId, canTry}),
      });

      try {
        // const htmlContent = await response.text();
        // const blob = new Blob([htmlContent], { type: 'text/html' });
        // const url = URL.createObjectURL(blob);
        // window.open(url, '_blank');
        const data = await response.json();
        console.log("Response data: ", data)
        if (data.url) {
          console.log("Data url: ", data.url)
          window.location.href = data.url; // Redirect to Stripe Checkout
        } else {
          console.error('Failed to create Stripe session');
        }

        // Optionally, revoke the object URL after a timeout to free up memory
        // setTimeout(() => URL.revokeObjectURL(url), 1000);
      } catch (error) {
        console.error('Error opening checkout:', error);
      }

      // const data = await response.json();

      // if (response.ok) {

      // } else {
      //   console.error('Subscription failed:', data.message);
      // }

    }catch(e) {
      console.log(`Error: ${e.message}`);
    }
    setIsSubscribing(false);
  };

  const [isSubscribing, setIsSubscribing] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)

  return (
    <div className="">
      <XButton/>
      <h1 className="subscription-title">Subscription</h1>
      {/* <SubscriptionCard/> */}
      <PricingCard 
        isSubscribed={isSubscribed} 
        handleSubscribe={handleSubscribe} 
        handleCancel={handleCancel}
        currentPlan={currentPlan}
        subscriptionDate={subscriptionDate}
        expirationDate={expirationDate}
        isSubscribing={isSubscribing}
        cta = {canTry ? "Start 7 day free trial" : "Subscribe"}
        isCancelling={isCancelling}
        canTry={canTry}
        />
      
    </div>
  );
};



export default SubscriptionScreen;
